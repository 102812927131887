define("subscriptionclient/helpers/classasign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.classasign = classasign;
  _exports.default = void 0;

  function classasign(params
  /*, hash*/
  ) {
    if (params[0] != null) {
      var status = params[0];
      var place = params[1];
      var classname = "nothinginside";
      var classemapper = {
        "Subscription": {
          "active": "rcornersactive2 tableheading",
          "cancelled": "rcornerscancel2 tableheading",
          "subscription_expired": "rcornersexpired2 tableheading",
          "frozen": "rcornersfrozen2 tableheading",
          "trial": "rcornerstrial2 tableheading",
          "past_due": "rcornerspastdue2 tableheading",
          "unpaid": "rcornersunpaid2 tableheading",
          "trial_expired": "rcornersexpired2 tableheading",
          "user_cancelled": "rcornerscancel2 tableheading"
        },
        "SubscriptionDetails": {
          "active": "rcornersactive1",
          "cancelled": "rcornerscancel1",
          "subscription_expired": "rcornersexpired1",
          "frozen": "rcornersfrozen1",
          "trial": "rcornerstrial1",
          "past_due": "rcornerspastdue1",
          "unpaid": "rcornersunpaid1",
          "trial_expired": "rcornersexpired1",
          "user_cancelled": "rcornerscancel1"
        },
        "Subscriptiondetailspay": {
          "failed": "rcornerscancel2",
          "initiated": "rcornersinitiated2",
          "success": "rcornersactive2",
          "payment_pending": "rcornerspending2",
          "payment_due": "rcornersdue2",
          "canceled": "rcornersexpired2",
          "awaiting_verification": "rcornersawaiting2"
        },
        "Payments": {
          "failed": "rcornerscancel2 tableheading",
          "initiated": "rcornersinitiated2 tableheading",
          "success": "rcornersactive2 tableheading",
          "payment_pending": "rcornerspending2 tableheading",
          "payment_due": "rcornersdue2 tableheading",
          "canceled": "rcornersexpired2 tableheading",
          "awaiting_verification": "rcornersawaiting2 tableheading"
        },
        "Paymentsdetails": {
          "failed": "rcornerscancel1",
          "initiated": "rcornersinitiated1",
          "success": "rcornersactive1",
          "payment_pending": "rcornerspending1",
          "payment_due": "rcornersdue1",
          "canceled": "rcornersexpired1",
          "awaiting_verification": "rcornersawaiting1"
        },
        "freezestatus": {
          1: "rcornerspending2",
          2: "rcornersactive2",
          3: "rcornerscancel2"
        },
        "Sub_status": {
          "trial": "Trial",
          "active": "Active",
          "past_due": "Past Due",
          "unpaid": "Unpaid",
          "subscription_expired": "Expired",
          "trial_expired": "Trial Expired",
          "cancelled": "Cancelled",
          "frozen": "Frozen",
          "user_cancelled": "User Cancelled"
        },
        "pay_status": {
          "failed": "Failed",
          "initiated": "Initiated",
          "success": "Success",
          "payment_pending": "Pending",
          "payment_due": "Due",
          "canceled": "Cancelled",
          "awaiting_verification": "Awaiting Verification"
        },
        "pay_type": {
          'card': "Card",
          'bank_transfer_international': "Bank Transfer International",
          'bank_transfer_domestic': "Bank Transfer Domestic",
          'check': "Check",
          'purchase_order_check': "P O Check",
          'purchase_order_bank_transfer': "P O Bank Transfer",
          'other': "Other",
          'wallet': "Wallet"
        },
        "Acc_sub": {
          "active": "account_subview_active",
          "cancelled": "account_subview_cancel",
          "subscription_expired": "account_subview_expired",
          "frozen": "account_subview_freeze"
        },
        "quote_type": {
          2: 'Custom Quote',
          1: 'Normal Quote'
        },
        "quote_cat": {
          2: 'Upgrade',
          1: 'Normal'
        },
        "quote_status": {
          0: 'Initiated',
          1: 'Approved',
          2: 'Paid - Validate',
          3: 'Paid',
          4: 'Cancelled',
          5: 'Expired',
          6: 'Need Approval',
          7: 'Accepted'
        },
        "quote_apply_status": {
          0: 'Static',
          1: 'Paid and Ready',
          2: 'Applied',
          3: 'Accepted and Ready'
        },
        "quote_apply_status_class": {
          0: 'rcornersinitial2',
          1: 'rcornerspending2',
          2: 'rcornersactive2',
          3: 'rcornersdue2'
        },
        "quote_status_class": {
          0: "rcornersinitial2 tableheading",
          1: "rcornersactive2 tableheading",
          2: "rcornerspending2 tableheading",
          3: "rcornersactive2 tableheading",
          4: "rcornerscancel2 tableheading",
          5: "rcornersexpired2 tableheading",
          6: "rcornerspending2 tableheading",
          7: "rcornersactive2 tableheading"
        },
        "quoteDetails": {
          0: 'rcornersinitiated1',
          1: "rcornersapproved1",
          2: "rcornerspending1",
          3: "rcornersactive1",
          4: "rcornerscancel1",
          5: "rcornersexpired1",
          6: "rcornerspending1",
          7: 'rcornersactive1'
        },
        "newpricingplan": {
          1: 'Hexnode Pricing Plan 2019',
          2: 'Hexnode Pricing Plan 2020',
          3: 'Hexnode Pricing Plan 2020 - 40-150 On-Board',
          4: 'Hexnode Pricing Plan 2020 - 50-150 On-Board',
          5: 'Hexnode Pricing Plan 2020 - 150-250 On-Board',
          6: 'Hexnode Pricing Plan 2020 - 250-500 On-Board',
          7: 'Hexnode Pricing Plan 2020 - 500+ On-Board',
          8: 'Hexnode Pricing Plan 2021',
          9: 'Hexnode Pricing Plan - Website Pricing',
          10: 'Hexnode Pricing Plan - Website Pricing 2022',
          11: 'Hexnode Pricing Plan - Website Pricing 2025'
        },
        "dunning_actions": {
          "show_banner": 'Show Banner',
          "restrict_access": 'Restrict Access',
          "policy_remove": 'Policy Remove',
          "disenrolment": 'Disenrollment',
          "purging": 'Purging',
          "allow_access": "Allow Access"
        },
        "newpricingplansub": {
          1: '2019',
          2: '2020',
          3: '2020(40-150 On-Board)',
          4: '2020(50-150 On-Board)',
          5: '2020(150-250 On-Board)',
          6: '2020(250-500 On-Board)',
          7: '2020(500+ On-Board)',
          8: '2021',
          9: '2021(Website Pricing)',
          10: '2022(Website Pricing)',
          11: '2025(Website Pricing)'
        },
        "resellercoupon": {
          1: 'Reseller',
          0: 'Normal'
        },
        "quotehistoryaction": {
          1: 'Quote Created',
          2: 'Quote Edited',
          3: 'Quote Verified',
          4: 'Quote Cancelled',
          5: 'Quote Deleted',
          6: 'Portal Assign/Removal',
          7: 'Quote Send',
          8: 'Quote Approved',
          9: 'Pay Button Added',
          10: 'Pay Button Removed',
          11: 'Quote Accepted',
          12: 'Quote Applied',
          13: 'Quote Download',
          14: 'Update payment method'
        },
        "quotehistoryaction_class": {
          1: 'rcornersinitial2',
          2: 'rcornersinitial2',
          3: 'rcornersactive2',
          4: 'rcornerscancel2',
          5: 'rcornerscancel2',
          6: 'rcornerspending2',
          7: 'rcornersinitial2',
          8: 'rcornersactive2',
          9: 'rcornersactive2',
          10: 'rcornerscancel2',
          11: 'rcornersactive2',
          12: 'rcornersactive2',
          13: 'rcornersinitial2',
          14: 'rcornersinitial2'
        },
        "MonthName": {
          0: 'January',
          1: 'February',
          2: 'March',
          3: 'April',
          4: 'May',
          5: 'June',
          6: 'July',
          7: 'August',
          8: 'September',
          9: 'October',
          10: 'November',
          11: 'December'
        },
        "MonthNameShort": {
          0: 'Jan',
          1: 'Feb',
          2: 'Mar',
          3: 'Apr',
          4: 'May',
          5: 'Jun',
          6: 'Jul',
          7: 'Aug',
          8: 'Sept',
          9: 'Oct',
          10: 'Nov',
          11: 'Dec'
        },
        "MonthNameFull": {
          'Jan': 'January',
          'Feb': 'February',
          'Mar': 'March',
          'Apr': 'April',
          'May': 'May',
          'Jun': 'June',
          'Jul': 'July',
          'Aug': 'August',
          'Sept': 'September',
          'Oct': 'October',
          'Nov': 'November',
          'Dec': 'December'
        }
      };

      if (place in classemapper) {
        if (status in classemapper[place]) {
          classname = classemapper[place][status];
        } else {
          classname = "nothinginside";
        }
      } else {
        classname = 'nothinginside';
      }

      return classname;
    } else {
      return null;
    }
  }

  var _default = Ember.Helper.helper(classasign);

  _exports.default = _default;
});